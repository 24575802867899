<template>
  <div class="views-layout-login position-absolute">
    <div class="slogan position-absolute">
      <h1>让服务更美好，让连锁更简单</h1>
      <p>微海翘财，连锁企业的财务管家。全新上线~</p>
    </div>
    <div class="content-box position-absolute bg-white d-flex flex-column">
      <div class="box-logo text-center">
        <router-link to="/">
          <cs-icon
            name="icon-logo1"
            type="symbol"
            style="height: 45px; width: auto"
          />
        </router-link>
      </div>
      <div class="flex-grow-1">
        <router-view></router-view>
      </div>
      <div class="box-footer text-center">
        <template v-if="isLoginPage">
          没有账号？
          <router-link to="/register" class="text-primary">
            立即注册</router-link
          >
        </template>
        <template v-else>
          已有账号，
          <router-link to="/login" class="text-primary"> 立即登录</router-link>
        </template>
      </div>
    </div>
    <CommonFooter />
  </div>
</template>
<script>
export default {
  components: {
    CommonFooter: () => import("@/components/layout/CommonFooter"),
  },
  data() {
    return {};
  },
  inject: ["loadUserInfo"],
  computed: {
    isLoginPage() {
      return this.$route.path == "/login";
    },
  },
  methods: {},
};
</script>
<style lang="less">
.views-layout-login {
  height: 100%;
  width: 100%;
  min-width: 1280px;
  overflow: auto;
  background: url("../../../public/img/login.png") no-repeat left center;
  background-size: cover;
  .slogan {
    top: 200px;
    left: 350px;
    h1 {
      font-size: 38px;
      color: rgba(0, 0, 0, 0.85);
      font-family: "经典雅黑";
    }
    p {
      font-size: 23px;
      color: rgba(134, 134, 134, 0.85);
    }
  }
  .content-box {
    width: 480px;
    height: 683px;
    box-shadow: 0px 2px 30px 0px rgba(0, 164, 151, 0.1);
    border-radius: 12px;
    top: 200px;
    right: 10%;
    .box-logo {
      padding-top: 70px;
      padding-bottom: 30px;
      img {
        height: 45px;
      }
    }
    .ant-form-item {
      margin-bottom: 22px;
      .ant-form-explain {
        position: absolute;
      }
    }
    .box-footer {
      padding: 30px;
    }
  }
  @media screen and (max-height: 1000px) {
    min-height: 880px;
    .slogan {
      top: 100px;
      left: 100px;
    }
    .content-box {
      top: 100px;
      right: 100px;
    }
  }
  @media screen and (max-height: 720px) {
    min-height: 800px;
    .slogan {
      top: 50px;
      left: 50px;
    }
    .content-box {
      top: 50px;
      right: 50px;
    }
  }
  .com-layout-CommonFooter {
    text-align: center;
    position: absolute;
    bottom: 30px;
    width: 100%;
  }
}
</style>
